.pswp img {
  max-width: none;
  object-fit: contain !important;
}

.pswp__img--placeholder--blank{
  display: none !important;
}

.pswp__bg {
  opacity: 1 !important;
}