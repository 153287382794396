body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,500;1,200;1,300;1,400&display=swap');

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Abadi-Light';
  src: local('Abadi'), url(./assets/AbadiMTStd-ExtraLight.woff2), format('.woff2');
}

@font-face {
  font-family: 'Abadi-Bold';
  src: local('Abadi'), url(./assets/AbadiMTStd-Bold.woff2), format('.woff2');
}

@font-face {
  font-family: 'Abadi-Italic';
  src: local('Abadi'), url(./assets/AbadiMTStd-Italic.woff2), format('.woff2');
}

@font-face {
  font-family: 'Abadi-Standard';
  src: local('Abadi'), url(./assets/AbadiMTStd.woff2), format('.woff2');
}
